<template>
  <v-dialog
    v-model="view.toggle"
    scrollable
    persistent
    transition="scroll-y-transition"
    :max-width="size"
  >
    <v-card 
      class="sheet"
    >
      <v-card-title>
        {{ $t('drivers.move_drivers') }}
      </v-card-title>
      <v-card-text>
        <div class="drivers mx-n2 mb-4">
          <v-chip
            v-for="(item, i) in controller.process"
            :key="'driver-'+i"
            :color="item.state"
            class="driver ma-2"
          >
            {{ item.driver.fullname }}
          </v-chip>
        </div>
        <v-select
          v-model="controller.status"
          :items="allowedStatus"
          :loading="controller.loading"
          :disabled="controller.loading||controller.disabled"
          :label="$t(controller.label)"
          menu-props="auto, light"
          hide-details
          outlined
          color="primary"
          class="select"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="!finished"
          text
          class="btn-cancel"
          @click="close(true)"
        >
          {{ $t('general.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="!finished"
          :loading="controller.loading"
          :disabled="controller.loading || !controller.status"
          text
          color="primary"
          class="btn-send"
          @click="setStatus"
        >
          {{ $t('general.move') }}
        </v-btn>
        <v-btn
          v-else
          text
          color="primary"
          class="btn-ok"
          @click="close(true)"
        >
          {{ $t('general.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
</style>

<script>

import services from '@/services.js'
import { status as statuses } from '@/services/driver'
import { _ } from 'core-js';
import { sync } from 'vuex-pathify'

export default {
  props: {
    toggle: {
      type: Boolean,
      default: false
    },
    drivers: {
      type: [Object, Array],
      default: () => null
    },
    status: {
      type: String,
      default: null
    },
    user: {
      type: String,
      default: ''
    },
    token: {
      type: String,
      default: ''
    },
  },

  data: () => ({
    view: {
      toggle: false,
    },
    controller: {
      status: null,
      label: 'drivers.control.status',
      loading: false,
      disabled: false,
      process: []
    }
  }),

  computed: {
    toast: sync('app/toast'),

    finished () {
      const list = this.controller.process;
      const pending = _.filter(list, ['state', 'pending']);
      return _.isEmpty(pending);
    },

    allowedStatus () {
      // const profile = this.drivers;
      const status = this.status;
      return !!status ? _.map(_.cloneDeep(statuses), (item) => {
        if (item.mustbe==null||_.indexOf(item.mustbe, status)>=0||(status==item.value)) {
          item.disabled = false;
        }else{
          item.disabled = true;
        }
        return {
          ...item,
          text: this.$t('drivers.status.'+item.value)
        };
      }) : null;
    },
    size () {
      return this.breakpoint(null, 'xs') ? '100vw' : this.breakpoint(null, 'sm') ? '60vw' : '32rem'
    },
  },

  watch: {
    toggle (v) {
      console.log('toggle batch', v);

      this.view.toggle = v;
      _.each(this.drivers, driver => {
        this.controller.process.push({
          state: 'pending',
          driver
        });
      });
    },
  },

  methods: {
    ...services,

    close (cancel=false) {
      if (cancel) {
        this.controller.status = null;
        this.controller.process = [];
        this.$emit('toggle-batch', false);
      }else{
        this.view.toggle = false;
      }
    },

    setStatus () {
      const list = this.controller.process;
      const pending = _.filter(list, ['state', 'pending']);
      if (_.isEmpty(pending)) {
        // finished process
        this.toggleToast(
          true,
          this.$t('drivers.batch_success'),
          5000,
          true
        );
        this.controller.loading = false;

        return;
      }
      console.log(true, 'driver.setStatus');
      this.controller.loading = true;

      const username = this.user;
      const token = this.token;
      const status = this.controller.status;
      const process = _.first(pending);
      const driver = process.driver;
      const cpf = this.rawData(driver.cpf);

      this.toggleToast(
        true,
        `${this.$t('general.moving')} (${list.length-pending.length}/${list.length})...`,
        5000,
        false,
      );

      this.$api.COM
        .get('/setstatusdriveradmin/'+username+'/'+token+'/'+cpf+'/'+status+'/?format=json')
        .then(response => {
          console.log('setStatus => ', response);
          driver.status.value = response.data.dados[0].status_motorista;
          driver.status.super = response.data.dados[0].super_status_motorista;
          driver.status.changedAt = this.$moment().toISOString();

          process.state = 'success';

          this.setStatus();
          // this.$emit('updated', this.profile);
        })
        .catch(error => {
          this.handleError(error);
          this.toggleToast(
            true,
            this.$t('drivers.batch_failed'),
            -1,
            true
          );
        })
        .finally(() => {
          console.log(false, 'driver.setStatus');
        })
    },

  }
}
</script>